@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700&display=swap);
.loading-spinner {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.employee-mode {
  position: fixed;
  bottom: 8px;
  left: 8px;
  color: #ffffff;
  padding: 8px 16px;
  z-index: 10;
  border-radius: 50px;
  border: 2px solid #1da57a;
  background-color: rgba(29, 165, 122, 0.5);
}

body,
html,
#root {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
body {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  min-width: 320px;
}

.post-title-admin {
  font-size: 20px;
  font-weight: 700;
}
.rtc-title {
  color: #7e7d7d;
}
.rtc-translator li[data-selected='true'] {
  box-shadow: inset 0 0 1px 1px #d4d3d3;
}
.filter-day {
  min-width: 100px;
}
.table-striped-rows tr:nth-child(2n) td {
  background-color: #fbfbfb;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}
.post-title-user {
  font-size: 20px;
  font-weight: 700;
}

