@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700&display=swap');

body,
html,
#root {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
body {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  min-width: 320px;
}
