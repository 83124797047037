.employee-mode {
  position: fixed;
  bottom: 8px;
  left: 8px;
  color: #ffffff;
  padding: 8px 16px;
  z-index: 10;
  border-radius: 50px;
  border: 2px solid rgb(29, 165, 122);
  background-color: rgba(29, 165, 122, 0.5);
}
